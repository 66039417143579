import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Router from 'next/router';
import { connect } from 'react-redux';
import { modulo } from 'utils';

import {
    fetchWorkDetailPageIfNeeded,
    setActiveProjectIndex,
    updateWorkPageView,
} from 'store';

import { workViews } from 'utils/constants';

import Hero from './Hero';
import Info from './Info';
import Interstitial from './Interstitial';
import Modules from './Modules';
import ProjectCarousel from 'components/ui/ProjectCarousel';
import Error from 'components/ui/Error';
import { ScrollContainer } from 'components/ui/AppLayout';

import KaleidoscopeContext from 'components/ui/Kaleidoscope/Context';

import styles from './WorkDetail.scss';

class WorkDetail extends React.Component {
    static contextType = KaleidoscopeContext;

    static getInitialProps = ({ store, query, res }) => {
        const slug = query && query.slug ? query.slug : '';
        const preventImageSwap = query && query.swapimage === 'nope';
        return store.dispatch(fetchWorkDetailPageIfNeeded(slug)).then(() => {
            const state = store.getState();
            const projects = state.pages['/work'].projects;
            const activeProjectIndex = projects.findIndex(
                project => project.slug.trim() === slug
            );
            const nextActiveProjectIndex = modulo(
                Math.abs(activeProjectIndex) + 1,
                projects.length
            );
            const workDetail = state.workDetailPages[slug];
            if (!workDetail && res) {
                res.statusCode = 404;
            }
            return {
                contentTypeId: workDetail ? workDetail.contentTypeId : '',
                nextActiveProjectIndex,
                preventImageSwap,
                workDetail,
            };
        });
    };

    static propTypes = {
        // Global pageProps from App.js getInitialProps
        work: PropTypes.shape({
            projects: PropTypes.array.isRequired,
        }).isRequired,
        // Component-specific props from getInitialProps
        contentTypeId: PropTypes.string,
        nextActiveProjectIndex: PropTypes.number.isRequired,
        preventImageSwap: PropTypes.bool.isRequired,
        workDetail: PropTypes.shape({
            project: PropTypes.shape({
                slug: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                image: PropTypes.object.isRequired,
                gradientColor1: PropTypes.string.isRequired,
                gradientColor2: PropTypes.string.isRequired,
            }).isRequired,
            headline: PropTypes.string.isRequired,
            capabilities: PropTypes.string.isRequired,
            url: PropTypes.string,
            description: PropTypes.string.isRequired,
            interstitial: PropTypes.array.isRequired,
            modules: PropTypes.array.isRequired,
        }),
        // Props passed directly from App.js render()
        fontsLoaded: PropTypes.bool.isRequired,
        // Mapped state and dispatchers
        setActiveProjectIndex: PropTypes.func.isRequired,
        updateWorkPageView: PropTypes.func.isRequired,
    };

    ref = React.createRef();

    topHalfVisible = true;

    handleScroll = target => {
        const top = target.scrollTop;
        const mid = (target.scrollHeight - window.innerHeight) / 2;
        if (this.topHalfVisible && top > mid) {
            const { work, nextActiveProjectIndex } = this.props;
            const nextProject = work.projects[nextActiveProjectIndex];
            this.context.showWorkImage(nextProject.image.file.url, 0);
            this.topHalfVisible = false;
        } else if (!this.topHalfVisible && top < mid) {
            const { project } = this.props.workDetail;
            this.context.showWorkImage(project.image.file.url, 0);
            this.topHalfVisible = true;
        }
    };

    handleProjectCarouselSnap = projectIndex => {
        if (projectIndex !== this.props.nextActiveProjectIndex) {
            this.props.setActiveProjectIndex(projectIndex);
            this.props.updateWorkPageView(workViews.carousel);
            Router.push('/work?transition=nope', '/work');
        }
    };

    render() {
        const {
            work,
            nextActiveProjectIndex,
            workDetail,
            fontsLoaded,
        } = this.props;
        if (!workDetail) {
            return <Error statusCode={404} />;
        }
        const {
            project,
            headline,
            capabilities,
            url,
            description,
            interstitial,
            modules,
        } = workDetail;
        const gradient = [project.gradientColor1, project.gradientColor2];
        return (
            <ScrollContainer
                className={styles.workDetail}
                onScroll={this.handleScroll}
            >
                <Head>
                    <title>
                        {project.client} | {project.name} | Deinde
                    </title>
                </Head>
                <Hero
                    copy={headline}
                    gradient={gradient}
                    fontsLoaded={fontsLoaded}
                    project={project}
                />
                <Info
                    capabilities={capabilities}
                    url={url}
                    description={description}
                    gradient={gradient}
                />
                <Interstitial items={interstitial} />
                <Modules items={modules} gradient={gradient} />
                <ProjectCarousel
                    className={styles.projectCarousel}
                    projects={work.projects}
                    activeProjectIndex={nextActiveProjectIndex}
                    onSnap={this.handleProjectCarouselSnap}
                    fontsLoaded={fontsLoaded}
                    disableVerticalScroll
                />
            </ScrollContainer>
        );
    }
}

const mapDispatchToProps = {
    setActiveProjectIndex,
    updateWorkPageView,
};

export default connect(
    null,
    mapDispatchToProps
)(WorkDetail);
